import './assets/main.css'

import { createApp } from 'vue';
import loadConfig from '@/services/configService';
import AppInitializationError from './layout/AppInitializationError.vue';

try {
  await loadConfig();

  import('./main');
} catch {
  const app = createApp(AppInitializationError);

  app.mount('#app');
}
