export interface Config {
  oidc: {
    issuer: string;
    clientId: string;
  };
  version: string;
}

let config: undefined | Config;

export const getConfig = () => {
  if (!config) {
    throw new Error('Could not get config');
  }
  return config;
};

const loadConfig = async () => {
  const response = await fetch('/api/config', {
    method: 'GET',
    credentials: 'include',
    mode: 'no-cors',
  });

  if (!response.ok) {
    throw new Error('Error loading config');
  }

  config = await response.json();

  // return config = {
  //   oidc: {
  //     issuer: 'https://oktapoc.customervoice360.com/oauth2/aus13napthrBGuBn90h8',
  //     clientId: '0oa1nkzg4fxmYSB880h8',
  //   },
  //   version: 'without backend',
  // } as Config;
};

export default loadConfig;
