<template>
  <div>
    <p>Tivian</p>
    <p>
      Application initialization failed
      <br />
      Please contact your administrator
    </p>
  </div>
</template>
